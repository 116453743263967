import React, {useEffect, useRef} from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Banner from "../../../components/banner"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Renta Fija"/>
            <Banner title="Renta Fija"/>
            <section className="service_details_area aspectos servicios">
                <div className="container">
                    <div className="section_title black_c">
                        <p className="f_16">Este paquete de soluciones se enfoca a aquellas empresas que ya han
                            incursionado en los
                            mercados, a través de la emisión de un instrumento de deuda y desean implementar un programa
                            de RI, ya sea
                            para ejecutar una subsecuente colocación de deuda más ágilmente o preparar las bases de un
                            IPO. IRStrat se
                            enfoca en mejorar la visibilidad en los mercados para una mayor demanda por los
                            instrumentos, obtener una
                            mejor operatividad (mayor liquidez del bono, así como valuaciones sobre par), y ampliar la
                            base
                            institucional de inversores. Este paquete incluye lo siguiente:</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="service_left_sidebar">
                                <ul className="nav nav-tabs service_tab mb_50" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active show" id="paquete1-tab" data-toggle="tab"
                                           href="#paquete1" role="tab"
                                           aria-controls="paquete1" aria-selected="true">Paquete 1</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete2-tab" data-toggle="tab" href="#paquete2"
                                           role="tab"
                                           aria-controls="paquete2" aria-selected="false">Paquete 2</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete3-tab" data-toggle="tab" href="#paquete3"
                                           role="tab"
                                           aria-controls="paquete3" aria-selected="false">Paquete 3</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete4-tab" data-toggle="tab" href="#paquete4"
                                           role="tab"
                                           aria-controls="paquete3" aria-selected="false">Paquete 4</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade active show" id="paquete1" role="tabpanel"
                                     aria-labelledby="paquete1-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#accordion"
                                                               href="#collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Núcleo estratégico
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse1" className="collapse show" aria-expanded="true"
                                                         role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Desarrollo de
                                                                    tesis de inversión
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Estructuración
                                                                    de ventaja competitiva
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Desarrollo de
                                                                    la narrativa de la historia
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Entrenamiento
                                                                    de los principales directivos en comunicación a los
                                                                    mercados
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Definición de
                                                                    estilo de comunicación (mix cualitativo y
                                                                    cuantitativo)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Comunicaciones del C-Suite
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Entrenamiento de los principales directivos
                                                                en comunicación a los mercados
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Preparación y
                                                                    asesoría para foros y presentaciones a
                                                                    inversionistas
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Elaboración de
                                                                    discurso trimestral
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Elaboración de
                                                                    discurso y asesoría en comunicaciones especiales
                                                                    (M&As, desinversiones, y otros eventos corporativos)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Asesoría en
                                                                    comunicaciones para manejo de crisis
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Estructuración de políticas,
                                                                procedimientos y métricas
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Estructuración
                                                                    de política de divulgación
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Mapeo de
                                                                    principales funciones
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Estructuración
                                                                    del diagrama de flujo de las funciones de RI
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Definición de
                                                                    principales indicadores de desempeño (KPIs)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Asesoría en
                                                                    gobierno corporativo
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse4"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Reportes a los mercados y
                                                                comunicaciones del día a día
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse4" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Elaboración y
                                                                    circle-up de reporte trimestral
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Elaboración y
                                                                    circle-up de reporte e informe anual
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Elaboración y
                                                                    actualizaciones trimestrales de presentaciones a
                                                                    inversionistas
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Actualizaciones
                                                                    trimestrales de investment fact sheet
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Asesoría en
                                                                    comunicación y estructuración de guía de resultados
                                                                    anual
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Elaboración de
                                                                    comunicados de prensa
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Punto de
                                                                    contacto para inversionistas
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Asesoría en
                                                                    entrevistas (redes sociales y medios de comunicación
                                                                    tradicionales)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete2" role="tabpanel"
                                     aria-labelledby="paquete2-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p2_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#p2_accordion"
                                                               href="#p2_collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="p2_collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Difusión y divulgación
                                                                estratégica</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse1" className="collapse show"
                                                         aria-expanded="true" role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Estrategia en
                                                                    envíos de wires
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Elaboración y
                                                                    envío de comunicados de reportes trimestrales de
                                                                    resultados e invitaciones a conferencias de
                                                                    resultados
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Divulgación a
                                                                    medios financieros y especializados
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Administración
                                                                    de listas de distribución
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="p2_collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Cumplimiento regulatorio</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Máximo
                                                                    cumplimiento con la CNBV
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Máximo
                                                                    cumplimiento con la CONSAR
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Máximo
                                                                    cumplimiento con la BMV
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Máximo
                                                                    cumplimiento con la BIVA
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Máximo
                                                                    cumplimiento con la SEC
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Identificación de
                                                                inversionistas</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Ejecución de
                                                                    estudios de percepción
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Estructuración
                                                                    de proxies de tenencia
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Elaboración de
                                                                    expedientes informativos de perfiles
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Sondeos
                                                                    post-resultados
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse4"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Prospección de inversionistas</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse4" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Targeting de
                                                                    inversionistas buy-side (deuda y
                                                                    capitales)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Asesoría en
                                                                    road shows y Non-deal road shows
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Presentaciones
                                                                    con las áreas de promoción
                                                                    bursátil
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Gestión de
                                                                    listas de distribución (creación y
                                                                    desarrollo)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Presentaciones
                                                                    y eventos con fondos de pensiones e inversión
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete3" role="tabpanel"
                                     aria-labelledby="paquete3-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p3_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#p3_accordion"
                                                               href="#p3_collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="p3_collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Cobertura de análisis
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse1" className="collapse show"
                                                         aria-expanded="true" role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Desarrollo de
                                                                    modelos de valuación de nodos y DCFs
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Targeting de
                                                                    inversionistas sell-side
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Presentaciones
                                                                    con las áreas de análisis
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p3_accordion" href="#p3_collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="p3_collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Eventos propios
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Planeación y
                                                                    logística para días de inversionistas
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Planeación y
                                                                    logística para visitas en sitio
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Planeación y
                                                                    logística de ruedas de prensa
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p3_accordion" href="#p3_collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Benchmarking</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Comparativos de
                                                                    resultados anuales (sector, mercado y región)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparativos de
                                                                    resultados trimestrales (sector, mercado y región)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparativos de
                                                                    sentimiento del mercado (posicionamiento y
                                                                    visibilidad)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete4" role="tabpanel"
                                     aria-labelledby="paquete4-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p4_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#p4_accordion"
                                                               href="#p4_collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="p4_collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Agenda estratégica
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p4_collapse1" className="collapse show"
                                                         aria-expanded="true" role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Eventos
                                                                    financieros nacionales e internacionales (cumbres,
                                                                    mesas redondas, foros, etc.)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Lobbying pro
                                                                    participación (buscamos su visibilidad)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p4_accordion" href="#p4_collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="p4_collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Sitio de RI
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p4_collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Estructuración
                                                                    y desarrollo gráfico
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Elaboración de
                                                                    contenido
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Plataforma
                                                                    móvil optimizada
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Herramientas
                                                                    interactivas (múltiplos de valuación, principales
                                                                    indicadores de desempeño e información bursátil)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p4_accordion" href="#p4_collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Servicios IR Edge 3.0</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p4_collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Web Analytics
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Servidores
                                                                    dedicados en la nube
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Servicios de
                                                                    traducción (español-inglés)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Interfase de
                                                                    inteligencia
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default Contenido
